import {appPrefix, cachePrefix, featureToggle, init, stylesPrefix} from '@ebexco/core/src/bootstrap';
import {conf} from '@ebexco/core/src/services/conf';
import {jsExt} from '@ebexco/core/src/utils/extensions';
import {urlParams} from '@ebexco/core/src/utils/urls.js';
import staging from '../conf/staging.js';

if (window.location.pathname.indexOf('/quote') > -1) {
  conf.app = 'results';
}

conf.featureToggles = {
  'uat': true,
  'uat-dev': true,
  'medicare-layout': true,
  'rebrand-dev': true,
};

featureToggle('rthealth');

if (urlParams.feature === 'staging' || urlParams.feature === 'results') {
  conf.baseApi = staging.baseApi;
}

init({
  scripts: [
    conf.app === 'index' && conf.baseUrl + cachePrefix + conf.app + jsExt,
    conf.baseUrl + stylesPrefix + conf.app + jsExt,
    conf.baseUrl + appPrefix + conf.app + jsExt,
  ],
});
